import * as Styled from "./headerWithBackgroungImageStyles";
import React from "react";

const HeaderWithBackgroundImage = ({ sectionData }) => {
  return (
    <Styled.Container
      $background={sectionData?.image?.url}
      className={sectionData?.title === "In The Media" ? "fixed" : ""}
    >
      <Styled.Centered>
        {sectionData?.title}
        <Styled.HR></Styled.HR>
      </Styled.Centered>
    </Styled.Container>
  );
};

export default HeaderWithBackgroundImage;
